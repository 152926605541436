
  .main{
    height: 100vh;
  }
  
  /* Navbar */
  .navbar2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #01a59c;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 70px;
  }
  
  .navbar-logo2 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #35605a;
    height: 70px;
    opacity: .92;
    width: 160px;
    box-shadow: 5px 7px 10px 0 rgba(0, 0, 0, .16);
  }
  
  .logo{
    width: 210px;
    height: 100px;
    margin: 5px;
  }
  
  .menu{
    width: 45px;
    height: 25px;
    margin: 5px;
  }
  .menu2{
    background: none;
    border: none;
  }
  
  .narvbar-content {
    display: flex;
    align-items: center;
  }
  
  .narvbar-titulo {
    font-size: 10px;
    margin-right: 20px;
  }
  
  /* Diseño específico del login */
  .narvbar-login2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
  }
  
  .narvbar-titulo {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.4;
    text-align: right;
  }
  
  .dropdown {
    display: flex;
    align-items: center;
  }
  
  .link-button {
    background: none;
    border: none;
    cursor: pointer;  
    color: #fff;
  }
  
  
  /* Tabla de productos 2 */
  .section-rigth2 {
    border-radius: 15px;
    margin: 10px 20px;
  }
  
  .fijado {
    color: white;
    background-color: #01a59c;
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .producto{
    margin: 0;
    padding: 12px ;
    font-weight: bold;
    flex: 2.5; 
    text-align: center; 
    font-size: 16px;
  }
  
  span {
    margin: 0;
    padding: 12px ;
    font-weight: bold;
    flex: 1; 
    text-align: center; 
    font-size: 16px;
  }
  
  
  .titulo-productos3 p {
    margin: 0;
    padding: 10px ;
    font-weight: bold;
    flex: 1; 
    text-align: center; 
  }
  
  /* Producto 2 */
  
  .productos-promo-scroll2{
    height: 76vh;
    overflow-y: auto;
  }
  
  .lista-producto-promo-general2 {
    display: flex;
    flex-direction: column;
  }
  
  .lista-producto-promo-content3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #dff7f7;
    border-radius: 8px;
  }
  
  .lista-producto-promo3 {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }
  
  .nombre-producto3 {
    flex: 3;
    font-size: 18px;
    font-weight: bold;
    color: black;
    margin: 0px;
    padding: 0px 10px;
  }
  
  .detalles-producto3 {
    display: flex;
    position: relative;
    margin: 5px;
  }
  
  .btn-toggle-menu {
    background-color: transparent;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    margin: 0px 10px;
  }
  
  .menu-desplegable {
    position: relative;
    right: 0;
    background-color: #ffffff21;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    border-radius: 4px;
    z-index: 1000;
  }
  
  .menu-desplegable button {
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: 15px;
    width: 42px;
    height: 35px;
    justify-content: center;
    align-items: center;
    color: #fffffffd;
    margin-top: 5px;
    border: none;
  }
  
  
  .cantidad-producto3 {
    flex: 1;
    font-size: 15px;
    font-weight: bold;
    color: black;
    margin: 0px;
    padding: 0px 10px;
    background-color: rgba(53, 96, 90, 0.18);
  }
  
  .precio-producto3 {
    flex: 1;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    margin: 0px;
    padding: 0px 10px;
    background-color: rgb(1, 165, 156);
  }
  
  .stock-producto3 {
    flex: 1;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    margin: 0px;
    padding: 0px 10px;
    background-color: rgb(3, 75, 47);
  }

  
  .btn-eliminar {
    background-color: #dc3545;
  }
  
  .btn-descuento {
    background-color: #01a59c;
  }
  .btn-menos {
    background-color: #dc3545;
  }
  .btn-mas {
    background-color: #01a59c;
  }

  .footer2 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
  }

  .sub__total {
    background-color: #009688;
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding: 10px 20px;
    border-radius: 10px;
    width: 50%;
    min-width: 150px;
  }
    
  /* Responsive design for phones */
  @media (max-width: 768px) {
    /* Adjust body background image */
    body {
      background-size: cover;
      background-attachment: fixed;
      height: 100%;
    }
  
    /* Navbar adjustments */
    .navbar1 {
      height: 70px;
    }
  
    .navbar-logo {
      width: 85px;
      justify-content: center;
      height: 70px;
    }
  
    .logo {
      display: none;
    }
  
    .menu {
      width: 45px;
      height: 25px;
      margin: 5px 0;
    }
  
    .narvbar-content {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .narvbar-titulo {
      font-size: 10px;
      margin: 0px;
      text-align: right;
    }
  
    .narvbar-login {
      display: flex;
      align-items: center;
      text-align: center;
      color: #fff;
    }
  
    .link-button{
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: inline;
      margin: 0;
      font-size: 40px;
      outline: none;
    }
  
   /* Footer */
   .footer1 {
    width: 100%;
    text-align: center;
    font-size: 14px;
    padding: 10px 0;
   }
  }
  