/* Estilos generales del cuerpo */
.abed-body {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Navbar */
.abed-navbar1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #01a59c;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: 70px;
}

.abed-navbar-logo {
  display: flex;
  align-items: center;
  background-color: #35605a9f;
  height: 70px;
  justify-content: center;
  opacity: .92;
  width: 85px;
  box-shadow: 5px 7px 10px 0 rgba(0, 0, 0, .16);
}

.abed-menu{
  width: 45px;
  height: 25px;
  margin: 5px 0px;
}
.abed-menu2{
  background: none;
  border: none;
}

.narvbar-content {
  display: flex;
  align-items: center;
}

.narvbar-titulo {
  font-size: 10px;
  margin-right: 20px;
}

/* Diseño específico del login */
.abed-narvbar-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
}

.abed-narvbar-titulo {
  color: #ffffff;
  font-size: 14px;
  line-height: 1.4;
  text-align: right;
}


.abed-link-button {
  color: #fff;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  font-size: 40px;
  outline: none;
}

/* Preventa container */
.abed-preventa__container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  justify-items: center;
  text-align: center;
  justify-content: center;
}

.abed-preventa__tipo--comprobante--select {
  width: 100%;
  padding: 8px;
  border: 2px solid #01a59c;
  border-radius: 5px;
  text-align: center;
}

.abed-agregar_cliente button {
  background-color: #01a59c;
  width: 100%;
  color: #fff;
  padding: 8px 12px;
  margin-bottom: 15px;
  height: 45px;
  border-radius: 10px;
  justify-content: center;
  text-align: center;
  text-decoration: none;
}
.abed-agregar_cliente_text{
  text-decoration: none;
  color: white;
}

.abed-DocCliente {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

/* Tabla de productos 1 */
.abed-section-left {
  border-radius: 15px;
  margin: 0 20px;
}

.abed-fijado2 {
  background-color: rgb(3, 75, 47);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  color: white;
  margin: 0px;
  text-align: center;
}


.titulo-productos {
  display: flex; 
  justify-content: space-between;
  align-items: center; 
}

.abed-span {
  margin: 0;
  padding: 10px ;
  font-weight: bold;
  flex: 1; 
  text-align: center; 
  font-size: 14px;
  padding-right: 0px;
}
.abed-span-pro{
  margin: 0;
  padding: 10px ;
  font-weight: bold;
  flex: 1; 
  text-align: center; 
  font-size: 14px;
  margin-left: 25px;
}

/* Producto 1 */

.abed-productos-promo-scroll {
  max-height: 300px;
  overflow-y: auto;
}

.abed-lista-producto-promo-content {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lista-producto-promo {
  width: 100%;
}

.nombre-prunitario4 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
  width: 100%;
  gap: 0;
}

.nombre-producto4 {
  font-weight: bold;
  margin: 0px;
  padding: 10px 6px;
  font-size: 16px;
  flex: 3; 
  text-align: left;
}

.precio-producto4 {
  color: white;
  background-color: rgb(1, 165, 156);
  padding: 14px 5px;
  font-size: 16px;
  flex: 1;
  text-align: center;
  margin: 0;
  border-left: 1px solid #ddd;
}

.stock-producto4 {
  background-color: rgba(53, 96, 90, 0.574);
  padding: 14px 5px;
  font-size: 16px;
  flex: 1;
  text-align: center;
  margin: 0;
  border-left: 1px solid #ddd;
}


/* Tabla de productos 2 */
section.section-rigth {
  border-radius: 15px;
  margin: 10px 20px;
}

.fijado3 {
  background-color: rgb(3, 75, 47);
  border: 3px solid rgb(11, 60, 41);;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  color: white;
  margin: 0;
  text-align: center;
  font-size: 12px;
}


.titulo-productos3 {
  display: flex; 
  justify-content: space-between;
  align-items: center; 
}

.titulo-productos3 p {
  margin: 0;
  padding: 10px ;
  font-weight: bold;
  flex: 1; 
  text-align: center; 
}


.titulo-productos3 p {
  margin: 0;
  padding: 10px ;
  font-weight: bold;
  flex: 1; 
  text-align: center; 
}

/* Producto 2 */

.productos-promo-scroll3 {
  max-height: 300px;
  overflow-y: auto;
}

.lista-producto-promo-general3 {
  display: flex;
  flex-direction: column;
}

.lista-producto-promo-content3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #dff7f7;
  border-radius: 8px;
}

.lista-producto-promo3 {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.nombre-prunitario3 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.nombre-producto3 {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.detalles-producto3 {
  display: flex;
  position: relative;
}

.btn-toggle-menu {
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  margin: 0px 10px;
}

.menu-desplegable {
  position: relative;
  right: 0;
  background-color: #ffffff21;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border-radius: 4px;
  z-index: 1000;
}

.menu-desplegable button {
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: 15px;
  width: 42px;
  height: 35px;
  justify-content: center;
  align-items: center;
  color: #fffffffd;
  margin-top: 5px;
  border: none;
}

.cantidad-producto3 {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin: 0px;
  padding: 0px 10px;
  background-color: rgba(53, 96, 90, 0.18);
}

.precio-producto3 {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin: 0px;
  padding: 0px 10px;
  background-color: rgb(1, 165, 156);
}

.stock-producto3 {
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin: 0px;
  padding: 0px 10px;
  background-color: rgb(3, 75, 47);
}


/* Footer */
footer {
  background-color: #b5c2c0;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.footer1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.total__container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.total__container p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #000;
}

.total__container strong {
  font-weight: bold;
  color: #000;
}

.seccion2-footer {
  display: flex;
  justify-content: space-between; /* Asegura separación entre los dos bloques */
  align-items: center; /* Centra verticalmente los elementos */
  padding: 10px; /* Espaciado interno */
  gap: 20px; /* Espaciado entre los dos bloques */
}

.sub__total {
  background-color: #009688;
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 10px 20px;
  border-radius: 10px;
  width: 50%; /* Tamaño fijo: 20% del contenedor padre */
  min-width: 150px; /* Ancho mínimo */
}

.letra__total {
  display: inline-block;
}

.continuar {
  width: 50%; /* Tamaño fijo: 75% del contenedor padre */
  min-width: 150px; /* Ancho mínimo */
}

.generar__button {
  background-color: #437a5f;
  color: white;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  position: relative;
  margin: 0px;
}

.generar__button:disabled {
  background-color: #437a5f;
}


/* Responsive design for phones */
@media (max-width: 768px) {
  /* Adjust body background image */
  body {
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
  }

  /* Navbar adjustments */
  .navbar1 {
    height: 70px;
  }

  .logo {
    display: none;
  }

  .narvbar-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .narvbar-titulo {
    font-size: 10px;
    margin: 0px;
    text-align: right;
  }

  .narvbar-login {
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
  }


  .main {
    padding: 10px;
  }

 /* Footer */
 .footer1 {
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding: 10px 0;
 }
}
